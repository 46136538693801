<template>
  <div class="action-wrapper">
    <div
      v-for="(action, index) in computedActions"
      :key="index"
      class="button-wrapper"
    >
      <a-dropdown v-if="action === 'edit'" :trigger="['contextmenu']">
        <a-button
          :type="buttonType(action)"
          @click="handleAction(action)"
          @click.middle="openNewEditPage"
        >
          <span>
            {{ action.charAt(0).toUpperCase() + action.slice(1) }}
          </span>
        </a-button>
        <a-menu slot="overlay">
          <a-menu-item key="1">
            <span @click="openNewEditPage">
              Open in a new Tab
            </span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-button v-else-if="action === 'reset' || action === 'force-accept'"
                @click="() => showConfirmModal(action)"
      >
        <span v-if="typeof action === 'object'">
          {{ action.label }}
        </span>
        <span v-else>
          {{ action.charAt(0).toUpperCase() + action.slice(1) }}
        </span>
      </a-button>
      <a-button
        v-else
        :type="buttonType(action)"
        @click="handleAction(action)"
      >
        <span v-if="typeof action === 'object'">
          {{ action.label }}
        </span>
        <span v-else>
          {{ action.charAt(0).toUpperCase() + action.slice(1) }}
        </span>
      </a-button>
      <!--      <button :class="{-->
      <!--                action,-->
      <!--                'btn btn-success': action === 'deposit' || action === 'copy'-->
      <!--                  || action === 'edit-balance' || action === 'approve',-->
      <!--                'btn btn-danger': action === 'delete' || action === 'cancel'-->
      <!--                  || action === 'reject_modal',-->
      <!--                'btn btn-info': action === 'view'
      || action === 'view*' || action === 'accept'-->
      <!--                  || action === 'play'
      || action === 'info' || action === 'verification',-->
      <!--                'btn btn-warning': action === 'edit'
      || action === 'withdraw' || action === '+ sms',-->
      <!--              }"-->
      <!--              @click="handleAction(action)"-->
      <!--      >-->
      <!--        {{ action.charAt(0).toUpperCase() + action.slice(1) }}-->
      <!--      </button>-->
    </div>
  </div>
</template>
<script>
import socketError from '../../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['actions', 'dataRowCell', 'rowId', 'updateTable', 'customCaseIsDeleted', 'searchParams', 'type'],
  computed: {
    computedActions() {
      if (this.actions.includes('delete*') && this.customCaseIsDeleted) {
        const actionIndex = this.actions.indexOf('delete*');
        const newActions = [...this.actions];
        newActions[actionIndex] = 'undelete*';
        return newActions;
      }
      return this.actions;
    },
  },
  methods: {
    async openNewEditPage() {
      window.open(`${this.$route.path}/?edit-view=1&row-id=${this.dataRowCell}`, '_blank');
    },
    async handleAction(action, route = '') {
      if (typeof action === 'object') {
        if (action.type === 'view') {
          await this.$router.push({ path: `${this.$route.path}/${action.postFix}/${this.dataRowCell}` });
        } else if (action.type === 'dynamicFormModal') {
          this.openDynamicFormModal(action.action);
        } else if (action.type === 'edit') {
          await this.openEditModal();
        }
        return true;
      }
      if (action === 'edit') {
        await this.openEditModal();
      } else if (action === 'edit-balance') {
        await this.openEditBalanceModal();
      } else if (action === 'Edit Wheel') {
        await this.editWheel();
      } else if (action === 'delete') {
        await this.openDeleteModal();
      } else if (action === 'delete*' || action === 'undelete*') {
        await this.openDeleteFormModal(action);
      } else if (action === 'view_image') {
        await this.openViewImageModal();
      } else if (action === 'viewtesttest*') {
        const routeData = this.$router.resolve(
          {
            path: `${this.$route.path}/${this.dataRowCell}`,
          },
        );
        window.open(routeData.href, '_blank');
      } else if (action === 'view' || action === 'view*') {
        await this.$router.push({ path: `${this.$route.path}/${this.dataRowCell}` });
      } else if (action === 'report-wheel-players' || action === 'report-wheel-history' || action === 'report-by-level') {
        await this.$router.push({ path: `${this.$route.path}/${this.dataRowCell}/${action}` });
      } else if (action === 'view details') {
        const queryParams = new URLSearchParams({
          ...this.searchParams,
        });
        await this.$router.push({ path: `${this.$route.path}/${this.dataRowCell}?${queryParams}&type=${this.type}` });
      } else if (action === 'deposit' || action === 'info' || action === 'withdraw') {
        await this.$router.push({ path: `${this.$route.path}/clients-transactions/${this.rowId}`, query: { type: action } });
      } else if (action === 'verification') {
        await this.$router.push({ path: `${this.$route.path}/${this.rowId}/verification` });
      } else if (action === 'reject_modal'
        && (this.$route.params.moduleName === 'client-documents' || this.$route.params.menuItem === 'client-file')) {
        this.openRejectModal(action);
      } else if (action === 'cancel' && this.$route.params.moduleName === 'withdraw-requests') {
        this.openRejectModal('cancel-form');
      } else if (action === '+ sms') {
        await this.$store.dispatch('player/addToSmsTextArea', this.dataRowCell);
      } else if (action === 'play') {
        await this.openIframeModal(action);
      } else if (action === 'approve') {
        await this.openApproveModal();
      } else {
        try {
          const res = await axios.get(`${this.$route.path}/${route}${this.rowId}/${action}`);
          this.updateTable();
          return res.data;
        } catch (e) {
          socketError({
          }, e);
          return false;
        }
      }
      return true;
    },
    buttonType(type) {
      if (typeof type === 'object') {
        return type.buttonType || 'primary';
      }
      let buttonType;
      switch (type) {
        case 'deposit':
        case 'edit':
        case 'Edit Wheel':
        case 'copy':
        case 'edit-balance':
        case 'approve':
          buttonType = 'primary';
          break;
        case 'delete*':
        case 'delete':
        case 'cancel':
        case 'reject_modal':
          buttonType = 'danger';
          break;
        case 'view':
        case 'view*':
        case 'accept':
        case 'play':
        case 'info':
        case 'verification':
          buttonType = 'default';
          break;
        case 'withdraw':
        case '+ sms':
        case 'undelete*':
          buttonType = 'dashed';
          break;
        default:
          buttonType = 'default';
      }
      return buttonType;
    },
    async openIframeModal(action) {
      await this.$store.dispatch('modals/toggleIframeModal', {
        reqUrl: `${this.$route.path}/${this.rowId}/${action}`,
      });
    },
    async openApproveModal() {
      await this.$store.dispatch('modals/toggleApproveModal', {
        id: this.dataRowCell,
      });
    },
    async editWheel() {
      await this.$router.push({ path: '/admin/wheel-control', query: { wheelId: this.dataRowCell } });
    },
    async openViewImageModal() {
      await this.$store.dispatch('modals/toggleViewImageModal', { url: this.dataRowCell });
    },
    async openDeleteFormModal(action) {
      const params = {
        path: this.$route.path,
        id: this.dataRowCell,
        action,
        moduleName: this.$route.params.moduleName || this.$route.params.module,
      };
      await this.$store.dispatch('modals/toggleDeleteFormModal', params);
    },
    async openDeleteModal() {
      const params = {
        path: this.$route.path,
        id: this.dataRowCell,
        moduleName: this.$route.params.moduleName || this.$route.params.module,
      };
      await this.$store.dispatch('modals/toggleDeleteModal', params);
    },
    async openEditBalanceModal() {
      const params = {
        id: this.dataRowCell,
      };
      await this.$store.dispatch('modals/toggleEditBalanceFormModal', params);
    },
    async openEditModal() {
      const params = {
        id: this.dataRowCell,
      };
      await this.$store.dispatch('modals/toggleEditFormModal', params);
      const query = _.clone(this.$route.query);
      query.modalId = this.dataRowCell;
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.modalId != this.dataRowCell) {
        await this.$router.push({ query });
      }
    },
    showConfirmModal(action) {
      const label = typeof action === 'object'
        ? action.label : action.charAt(0).toUpperCase() + action.slice(1);
      const handleAction = this.handleAction.bind(this);
      this.$confirm({
        okText: 'Yes',
        cancelText: 'No',
        title: `Do you want to ${label} this item?`,
        onOk() {
          return handleAction(action);
        },
        onCancel() {},
      });
    },
    openRejectModal(action) {
      const params = {
        id: this.rowId,
        path: this.$route.path,
        action,
      };
      this.$store.dispatch('modals/toggleRejectModal', params);
    },
    openDynamicFormModal(action) {
      const params = {
        id: this.rowId,
        path: this.$route.path,
        action,
      };
      this.$store.dispatch('modals/toggleDynamicFormModal', params);
    },
  },
};
</script>

<style scoped lang="scss">
.action-wrapper{
  display: flex;
  justify-content: center;
  .button-wrapper{
    /*display: inline-block;*/
  }
  a{
    color: #dfe5fb;
  }
  button{
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  button:last-child{
    margin-right: 10px;
  }
}
</style>
